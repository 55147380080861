import * as React from 'react'
import Layout from '../../templates/layout'
import Seo from '../../helpers/seo'
import ContactForm from '../../components/organisms/ContactForm'
import CasesRelational from '../../components/organisms/CasesRelational'
import AICases from './ai-cases.json'
import Content from './content'
import Banner from '../../components/molecules/Banner'

const AILabs = () => {
  const { relational } = AICases

  return (
    <Layout page="AI Labs">
      <Banner image="AI-laboratory-project.webp" />
      <Content />
      <ContactForm />
      <CasesRelational relationalData={relational} currentCategory="AI Labs" />
    </Layout>
  )
}
export const Head = () => <Seo title="AI Labs" />

export default AILabs
